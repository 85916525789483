import React from "react";
import styled, { keyframes } from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/button";
import FullBody from "../components/graphics/FullBody";
import clubGif from "../images/background_club.gif";

const Container = styled.div`
  width: 100vw;
  text-align: center;
`;

const bouncy = keyframes`
  0% {
    transform: translate(0)
  }
  40% {
    transform: translateY(-20px)
  }
  80% {
    transform: translateY(30px)
  }
  100% {
    transform: translate(0)
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
const AbsoluteContainer = styled.div`
  position: absolute;
  top: ${({ top }) => top || `30px`};
  left: ${({ left }) => left || `20px`};
  ${({ fullWidth }) =>
    fullWidth &&
    `
  width: 100vw;
`}
  ${({ zIndex }) =>
    zIndex &&
    `
  z-index: ${zIndex};
`}
`;
const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: -10;
  img {
    margin-bottom: 0;
    width: 100vw;
  }
`;
const BouncingBox = styled.div`
  height: 700px;
  display: flex;
  animation: ${bouncy} 0.5s ease-in-out infinite;
  > div {
    align-self: flex-end;
  }
`;
const SceneContainer = styled.div`
  display: flex;
  position: relative;
  margin: 60px auto 0 auto;
  z-index: 10;
`;

const RandomScoreContainer = styled.div`
  color: #fff;
  font-family: "Barlow", sans-serif;
  padding: 10px;
  line-height: 60px;
`;
const Score = styled.div`
  color: #fff;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  font-size: 100px;
`;

const SaveResult = ({ location }) => {
  const { body } = location.state || {};
  const randomScore = React.useMemo(() => {
    return Math.floor(Math.random() * 100);
  }, []);
  return (
    <Layout>
      <SEO title="Dress Up 4 Santa: Yeah!" />
      <Container>
        <BackgroundContainer>
          <img src={clubGif} alt="Club gif" />
        </BackgroundContainer>

        <AbsoluteContainer zIndex={11}>
          <Button to="/dressing-room" state={{ body }}>
            Back
          </Button>
        </AbsoluteContainer>
        <div style={{ position: "absolute", right: 45, top: 20 }}>
          <RandomScoreContainer>
            Amazing...
            <Score>{randomScore}</Score>/100
          </RandomScoreContainer>
        </div>
        <ContentContainer>
          <SceneContainer>
            <BouncingBox>
              <FullBody width={360} initialValues={body} animated />
            </BouncingBox>
          </SceneContainer>
        </ContentContainer>
        <div
          style={{
            display: "flex",
            position: "fixed",
            bottom: "20px",
            width: "100vw",
            padding: "20px",
          }}
        >
          <div style={{ flex: 1, textAlign: "left" }}>
            <Button to="/start">Start Over!</Button>
          </div>
          <div>{/* <Button>Share with Friends</Button> */}</div>
        </div>
      </Container>
    </Layout>
  );
};
export default SaveResult;
